<template>
  <div class="page-content">
    <b-row>
      <b-col md="10">
        <page-breadcrumb title="Level Pass Management" class="mb-3" />
      </b-col>
    </b-row>
    <data-create-popup title="Create Level Pass"
      :schema="create_schema" :default-data="{name: null, start_time: null, end_time: null}"
      :create-data-fn="createItem" @create="$refs.group_list.getList()"
    />
    <data-table-ssr id="group_list" ref="group_list"
      :columns="fields" :get-list-fn="getList"
      :to-edit-fn="toEditPage"   
      :editRowFn="editRow"  
    />
  </div>
</template>

<script>
import service from "../service";
const create_schema = [
  {
    cols: 12,
    fields: [
      { label: 'Name', field: 'name', validate: { required: true } },
      { label: 'Start Time', field: 'start_time', input_type: 'datetime', validate: { required: true}},
      { label: 'End Time', field: 'end_time', input_type: 'datetime', validate: { required: true}}
    ]
  }
];
const fields = [
  { label: 'Name', field: 'name' },
  { label: 'Product ID', field: 'product_id' },
  { label: 'Start Time', field: 'start_time', input_type: 'datetime'},
  { label: 'End Time', field: 'end_time', input_type: 'datetime'},
  { label: 'Status', field: 'is_enable', input_type: 'switch', editable: true},
];
export default {

  data() {
    return {
      create_schema,
      fields,
    };
  },

  created() {
  },

  methods: {
    async getList({ limit, page, query }) {
      let response_data = await service.getList({
        query: JSON.stringify({ ...query }),
        page, limit,
      });
      let list = [], total = 0;
      if (response_data) {
        list = response_data.list;
        total = response_data.total;
        this.total = total;
      }
      return { list, total };
    },
    async createItem(data) {
      let new_item = await service.create({ ...data });
      return new_item;
    },
    toEditPage({ _id }) {
      this.$router.push({ name: 'level-pass-detail', params: { id: _id } });
    },
    async editRow(row){
      let data = {...row};
      await service.update({
          data: JSON.stringify(data),
      });
    }
  },
};
</script>
